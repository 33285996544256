// @flow
import { PageContent, SEO } from "@components";
import { Analytics } from "@containers";
import { ContentModelMapper } from "@utils";
import { Constants } from "@utils";
import { StorageContext } from "@utils/BrowserStorage/StorageContext";
import { PAGE_TYPES } from "@utils/Constants";
import { LocaleContext } from "@utils/Localization/LocaleContext";
import withURLParameters from "@utils/ParametersHOC";
import { graphql } from "gatsby";
import React from "react";

import { contentfulTypenames, determineStyle } from "../utils";
import { generateBreadcrumb } from "../utils/Breadcrumb";
import { LEAD_SCORING_DEFAULT_VALUES } from "../utils/Constants";
import { loadLocalStorageId } from "../utils/LocalStorage";
import { getHeaderData } from "../utils/PageHeader";

const Page404Template = ({ data, query, location }: *) => {
    const {
        page,
        navigation,
        footer,
        market,
        site: {
            siteMetadata: { siteUrl },
        },
    } = data;

    const pageSlug = "404";

    const navData = determineStyle("Default", page.navigation || navigation);

    return (
        <Analytics.PageWrapper
            key={page.id}
            entry={page.id}
            type={PAGE_TYPES.PAGE_404}
            title={page.title}
            language={page.node_locale}
            urlParameters={query}
            slug={pageSlug}
            segments={page.segments}
            leadScoringValue={
                page.leadScoringValue ||
                LEAD_SCORING_DEFAULT_VALUES[PAGE_TYPES.PAGE_404]
            }
        >
            <LocaleContext.Provider
                value={{
                    language: page.node_locale,
                    market,
                }}
            >
                <StorageContext.Provider
                    value={loadLocalStorageId(Constants.QUERY_PARAMETERS)}
                >
                    <SEO
                        title={page.title}
                        translations={page.translations}
                        publishedMarkets={page.markets}
                        metaDescription={page.metaDescription}
                        siteUrl={siteUrl}
                        currentPath={location.pathname}
                        page={page}
                        market={market}
                    />
                    <PageContent
                        navigation={navData}
                        footer={page.footer || footer}
                        translations={page.translations}
                        header={
                            page.pageHeader &&
                            ContentModelMapper.map({
                                ...getHeaderData(page, market),
                                breadcrumb: generateBreadcrumb(page, market),
                                backgroundCard: true,
                                parentContainer:
                                    contentfulTypenames.pageTypeEvent,
                            })
                        }
                        entry={page.content}
                        publishedMarkets={page.markets}
                        slug={pageSlug}
                        enableScrollTop={page.enableScrollTop}
                        hideCategoryBar
                        hideCategoryBarMobile
                    />
                </StorageContext.Provider>
            </LocaleContext.Provider>
        </Analytics.PageWrapper>
    );
};

export default withURLParameters(Page404Template);

export const query = graphql`
    query(
        $id: String!
        $language: String!
        $marketCode: String!
        $channel: String!
        $categoryId: String = null
    ) {
        ...SiteMetadata
        page: contentfulPageTechnical(
            contentful_id: { eq: $id }
            node_locale: { eq: $language }
        ) {
            id
            contentful_id
            __typename
            node_locale
            title
            pageHeader {
                ... on ContentfulDisplayHeader {
                    __typename
                    ...DisplayHeader
                }
                ... on ContentfulDisplayHero {
                    __typename
                    rtTitle {
                        raw
                    }
                    tagline
                    heroSize
                    callToAction {
                        ...CallToAction
                    }
                    asset {
                        ... on ContentfulImageBlock {
                            ...PageLandingHeroImageQuery
                        }
                        ... on ContentfulVideoBlock {
                            ...VideoBlock
                        }
                        ...MarketSpecificAsset
                    }
                }
            }
            markets {
                ...MarketGeneral
            }
            content {
                ...DisplaySection
                ...PageTechnicalContent
                ...VariantContainer
            }
            navigation {
                ...Navigation
            }
            footer {
                ...Footer
            }
            enableScrollTop
            node_locale
            translations {
                node_locale
            }
        }
        market: contentfulMarket(
            code: { eq: $marketCode }
            node_locale: { eq: $language }
        ) {
            ...MarketGeneral
        }
        navigation: contentfulNavigation(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Navigation
        }
        footer: contentfulFooter(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Footer
        }
    }
`;
